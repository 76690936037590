<template>
    <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/mtn_logo.png" style="height: 140px;width: 40%;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: rgb(0, 0, 0);">تسجيل دخول</h5>
              </div>
              <form  @submit.prevent="onLogin()">
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                      <input type="text" v-model="msisdn" class="form-control" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'" style="text-align: center;">
                    </div>
                </div>
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase">
                <span v-if="loading" class="px-1 arabickufi">جاري تسجيل الدخول</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">تسجيل الدخول</span>
            </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPMTN } from '@/Api/http-mtn'
import { useToast } from "vue-toastification";
export default {
  name: 'AppLogin',
  data() {
    return {
        msisdn: "",
        loading: false,
    };
  },
  // mounted() {
  //   if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
  //       this.$router.push({ path: "/" })
  //   }
  // },
  methods: {
        async onLogin() {
            const toast = useToast();
          let input = document.querySelector(".input");
          let button = document.querySelector(".button");
          button.disabled = true;
          input.addEventListener("change", stateHandle);

          function stateHandle() {
            if(document.querySelector(".input").value === "") {
               button.disabled = true;
            } else {
               button.disabled = false;
            }
          }
          if (this.msisdn != "") {
               var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
               if (this.msisdn.match(phoneno)) {
                    if (this.msisdn.startsWith("09")) {
                        let phone = this.msisdn.substr(1);
                        this.loading = true
                        await HTTPMTN.post(`CheckSubscribtion.php?msisdn=${249+phone}&service_code=9188`).then((res) => {
                            if (res.data.message == "OK" && res.data.MINTUES > 0) {
                                this.$cookie.setCookie('message', res.data.message, { expire: 60 * res.data.MINTUES, })
                                this.$cookie.setCookie('msisdn', "249"+phone, { expire: 60 * res.data.MINTUES, })
                                this.$cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                                setTimeout(() => this.$router.push({ path: "/" }), 2500);
                            } else {
                                toast.info("عفوا انت لست مشترك في الخدمة " , { timeout: 2000 });
                                // window.open("http://test.zaindsp.com:3033/?p=3136914724", "_blank");	
                                setTimeout(() => this.$router.push({ path: "/Login" }), 2500);
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                           this.loading = false;
                        });
                  } else {
                     toast.info("رقم الهاتف ليس ام تي ان" , { timeout: 2000 });
                  }
               } else {
                  toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 09 " , { timeout: 2000 });
               }
            } else {
               toast.info("الرجال ادخال رقم الهاتف" , { timeout: 2000 });
            }
        }
    },
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: #fff;
  background: rgb(0, 0, 0) none repeat scroll 0% 0% !important
}

.bg-dark {
  background: linear-gradient(0deg, #FC0 0, #FC0);
}
</style>